import { Component } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import * as  $ from "jquery";
import AOS from 'aos';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  Option:number = 1;
  getInTouch:Boolean = false;

  public inputName:string = null;
  public inputEmail:string = null;
  public inputOption: string = null;
  public textarea:string = null;
  public messageForm:string
  public title = "GnConsulting";

  email = { 
    name: '',
    email: '',
    option: '',
    message: ''
  };

  constructor(private httpClient:HttpClient){}

  ngOnInit() {
    AOS.init({
      duration: 1000,
      easing: "ease-in-out",
      once: true,
      mirror: false
      }
    );
 }

  validator(){
    if (this.inputName == undefined || this.inputEmail == undefined || 
      this.inputOption == undefined || this.textarea == undefined || 
      this.inputName.length < 1 || this.inputEmail.length < 1 ||
      this.inputOption.length < 1 || this.textarea.length < 1){
      this.messageForm = "Erro: Please fill all filds"
    }else{
      this.messageForm = "Success: Email sent!"
      console.log(this.inputName)
      console.log(this.inputEmail)
      this.sendEmail()
    }
  }
  sendEmail(){
    this.email.name = this.inputName //fill the object
    this.email.email = this.inputEmail
    this.email.option = this.inputOption.toLocaleLowerCase()
    this.email.message = this.textarea
    console.log(this.email)
    
    this.httpClient.post(`https://a2oviywj85.execute-api.ap-southeast-2.amazonaws.com/form`, this.email) //sending
    .subscribe(
      (data:any)=>{
        console.log(data)
      }
    )
  }
  
  Options(Option){
    this.Option = Option
  }
}
