<!doctype html>
<html lang="en">
  <head>
    <!-- Required meta tags -->
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">
    <!-- Bootstrap CSS -->
    <link rel="stylesheet" href="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/css/bootstrap.min.css" integrity="sha384-JcKb8q3iqJ61gNV9KGb8thSsNjpSL0n8PARn9HuZOnIxN0hoP+VmmDGMN5t9UJ0Z" crossorigin="anonymous">
  </head>
  <body>
  
    <div class="container col-sm-12 col-md-10">
       <!--navbar-->
      <nav class="navbar navbar-expand-sm navbar-dark">
        <a class="navbar-brand" href="#"><img class="logo-image"  src="assets/images/gnc-white-logo.png" alt="Responsive image"></a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNavAltMarkup">
          <div class="navbar-nav ml-auto">
            <a class="nav-links btn1 webfont mx-3" href="#OurServices"><span></span>services <span class="sr-only">(current)</span></a>
            <a class="nav-links btn1 webfont mx-3" href="#"><span></span>careers</a>
            <a class="nav-links btn1 webfont mx-3" href="#getInTouch"><span></span>contact</a>
          </div>
        </div>
      </nav>

      <!--Title-->
      <div class="container" id="containertitle">
        <div class="container" id="titleContainer">
          <h1 data-aos="fade-up" data-aos-duration="500" class="title text-white text-center text-sm-left">Innovate, today</h1>
          <h3 data-aos="fade-up" data-aos-duration="1000" class="subtitle text-white text-justify text-lg-left mt-4">Our suite of products will empower you to what you do best</h3>
          <button data-aos="fade-up" data-aos-duration="1500" class="btn btn-primary col-sm-12 col-md-6  col-lg-5 col-xl-4 my-3" id="btntitle">LEARN MORE</button>
        </div>
      </div>
    </div>

     <!--OurServices-->
    <div class="col-12" id="OurServices">
      <div class="container col-sm-12 col-lg-10">
        <h1 data-aos="fade-down" class="text-white mb-5 text-center text-md-left" id="titleOurServices">Our Services</h1>
        <hr data-aos="flip-left" color="white">
         <!--options button-->
         <div class="container justify-content-center mt-5 col-12">
          <button data-aos="zoom-in-left" data-aos-duration="500" class="btn2 col-6 col-md-3" id="optinsOurSer" (click)= "Options(1)">
            <span></span>
           IoT services <br>
            (Eva)
          </button>
          <button data-aos="zoom-in-left" data-aos-duration="700" class="btn2 col-6 col-md-3" (click)= "Options(2)">
            <span></span>
            Container <br> services (Paulo)
          </button>
          <button data-aos="zoom-in-left" data-aos-duration="900" class="btn2 col-6 col-md-3" (click)= "Options(3)">
            <span></span>
            Development <br> services (Amy)
          </button>
          <button data-aos="zoom-in-left" data-aos-duration="1100" class="btn2 col-6 col-md-3" (click)= "Options(4)">
            <span></span>
            Business services <br> (CapsLog)
          </button>
        </div>
        
        <!--options content-->
        <div *ngIf="Option == 1 " class="optionsContent container">
          <div class="row align-items-center">
            <div class="col-12 col-md-6">
                <img data-aos="fade-up" src="assets/images/dev-icon.png" alt="dev-icon" class="figure-img img-fluid col-8 mx-auto d-block ">
            </div>  
            <div class="col-12 col-md-6">
                <h1 data-aos="fade-left" class="webfont contantTitle text-center text-md-left text-white">Unleash IoT with Eva</h1>
                <p data-aos="fade-left" class="webfont contantSubtitle text-justify text-md-left text-white">Manage and secure device communication, integrate with cloud providers and remotely commandeer your fleet. All that and much more with Eva.</p>
                <button data-aos="fade-left" id="option1" class="btnOptions col-12 col-lg-6 mt-3"> <a id="btnlink" href="https://eva.gncloud.nz/"> Go to Eva</a></button>
            </div>
          </div>
        </div>

        <div *ngIf="Option == 2 " class="optionsContent container">
          <div class="row align-items-center">
            <div class="col-12 col-md-6">
                <img data-aos="fade-up" src="assets/images/dev-icon.png" alt="dev-icon" class="figure-img img-fluid col-8 mx-auto d-block ">
            </div>  
            <div class="col-12 col-md-6">
                <h1 data-aos="fade-left" class="webfont contantTitle text-center text-md-left text-white">Deploy with Paulo</h1>
                <p data-aos="fade-left" class="webfont contantSubtitle text-justify text-md-left text-white">Deploy, manage and secure enterprise Kubernetes, integrate with cloud providers and run containerized workloads in Production. Paulo is the next generation of Kubernetes, simplified.</p>
                <button data-aos="fade-left" id="option1" class="btnOptions col-12 col-lg-6 mt-3"><a id="btnlink" href="#"> Go to Paulo</a></button>
            </div>
          </div>
        </div>

        <div *ngIf="Option == 3 " class="optionsContent container">
          <div class="row align-items-center">
            <div class="col-12 col-md-6">
                <img data-aos="fade-up" src="assets/images/dev-icon.png" alt="dev-icon" class="figure-img img-fluid col-8 mx-auto d-block ">
            </div>  
            <div class="col-12 col-md-6">
                <h1 data-aos="fade-left" class="webfont contantTitle text-center text-md-left text-white">Develop with Amy</h1>
                <p data-aos="fade-left" class="webfont contantSubtitle text-justify text-md-left text-white">Code, build, test and run applications from your browser in a rich IDE. With Amy, your code and your application are one, and your applications will run on production as they run on any machine.</p>
                <button data-aos="fade-left" id="option1" class="btnOptions col-12 col-lg-6 mt-3"><a id="btnlink" href="#">Go to Amy</a></button>
            </div>
          </div>
        </div>

        <div *ngIf="Option == 4 " class="optionsContent container">
          <div class="row align-items-center">
            <div class="col-12 col-md-6">
                <img data-aos="fade-up" src="assets/images/dev-icon.png" alt="dev-icon" class="figure-img img-fluid col-8 mx-auto d-block ">
            </div>  
            <div class="col-12 col-md-6">
                <h1 data-aos="fade-left" class="webfont contantTitle text-center text-md-left text-white">Automate your Business with CapsLog</h1>
                <p data-aos="fade-left" class="webfont contantSubtitle text-justify text-md-left text-white">Capture documents with ease, customize your data entry flows and secure your data. CapsLog is data automation in a few clicks, without the IT complexity.</p>
                <button data-aos="fade-left" id="option1" class="btnOptions col-12 col-lg-7 mt-3"><a id="btnlink" href="#">Go to CapsLog</a></button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--Our promisse-->

    <div id="ourPromisseLine1">
      <div class="row ">
            <img src="assets/images/photo002.jpg" class="img-fluid d-block col-12 col-md-6">  
        <div class="col-12 col-md-6" id="ourPromisseBlock2">
            <h1 data-aos="fade-left" class="webfont text-center text-lg-left mx-lg-5 text-white" id="titleOurPromisse">Our promise</h1>
          <p data-aos="fade-left" class="webfont text-center text-lg-left mx-5 mx-lg-5 mt-2 text-white">We help startups to build and grow in an end-to-end cost-effective way, using free open-source software.</p>
          <div class="col-12">
            <button data-aos="fade-up" class="btn button-cta-learn-more mx-3 mx-lg-4 mt-3 col-11 col-lg-5">LEARN MORE</button>
          </div>    
        </div>
      </div>
    </div>

    <!--Second Line-->

    <div id="ourPromisseLine2">
      <div class="row align-items-center"><!--One row Line-->
        <!--This block will disappear in small screens-->
        <div class="d-none d-md-block col-12 col-md-5" id="ourPromisseBlock3"><!--One block Line-->
          <p data-aos="fade-right" class="webfont mx-5 text-center text-lg-left text-white" id="OurSoulSubtitle" >Our solutions harvest the power of open-source and community-driven development to fast-track your products.</p>
        </div>

        <img src="assets/images/portfolio-6.jpg" id="imgSecondLine" class="col-12 col-md-4 col-lg-3">

        <div class="col-12 col-md-3 col-lg-4"  id="ourPromisseBlock5"><!--One block Line-->
           <h1 data-aos="fade-left" class="webfont text-center text-white" id="titleOurPromisse" >Our soul</h1>
           <!--This line will appear in small screens-->
           <p data-aos="fade-right" class="webfont d-block d-md-none mx-5 mt-2 text-center text-lg-left text-white" id="OurSoulSubtitle" >Our solutions harvest the power of open-source and community-driven development to fast-track your products.</p>
        </div>
      </div>
    </div>

    <!--Last Line-->

    <div id="ourPromisseLine1">
      <div class="row align-items-center"> <!--One row Line-->
        <div class="d-none d-md-block col-12 col-md-4" id="ourPromisseBlock6"> <!--One block Line-->
          <h1 data-aos="fade-up" class="webfont mx-5 mx-md-0 text-center text-white" id="ApproachableTitle">Approachable</h1>
        </div>

           <!--One block Line-->
          <img src="assets/images/portfolio-1.jpg" id="imgSecondLine" class="img-fluid d-block col-12 col-md-4 col-lg-3">
      
        <div class="col-12 col-md-4 col-lg-5" id="ourPromisseBlock8"><!--One block Line-->
          <h1 data-aos="fade-up" class="webfont d-block d-md-none mx-5 mx-md-0 text-center text-white" id="titleOurPromisse">Approachable</h1>
          <p data-aos="fade-up" class="webfont mx-5 text-center text-lg-left text-white" id="ApproachableSubtitle">If what you need requires some customization, we will be able to fit you in just fine.</p>
        </div>
      </div>
    </div>

     <!--Get in touch-->
     
    <div class="col-12" id="getInTouch">
      <div class="container col-10">
      <div class="row" id="getInTouchRow">
        <h1 data-aos="fade-right" class="webfont text-white text-center text-lg-left col-12 col-lg-7" id="startYourJorney">Start your journey, today!</h1>
        <div class="col-2"></div>
        <button data-aos="fade-left" class="button-cta-learn-more col-12 col-lg-3" type="button" data-toggle="collapse" data-target="#showform" aria-expanded="false" aria-controls="showform">Get in touch</button>
        <!-- Show form after click on Get in touch button -->
        <div class="collapse col-12" id="showform">
        <div class="container justify-content-center mb-5 col-12 col-lg-6">
          <!--Form Get in Touch-->
          <form action="" method="post" ngNativeValidate>
            <div class="form-row justify-content-center">
              <div class="form-group col-sm-12">
                <label for="inputName" class="webfont text-white mt-5">Full Name: </label>
                <input type="text" class="form-control " name="inputName" id="name" [(ngModel)]="inputName" placeholder="Name:" required>
              </div>
              <div class="form-group col-sm-6 ">
                <label for="inputName" class="webfont text-white">Email: </label>
                <input type="email" class="form-control " name="inputEmail" id="email" [(ngModel)]="inputEmail"  placeholder="Email:" required>
              </div>
              <div class="form-group col-sm-6">
                <label for="option" class="webfont text-white">Select topic: </label>
                <select class="form-control shadow-sm" id="option" name="inputOption" [(ngModel)]="inputOption" required>           
                  <option>Eva</option>
                  <option>Paulo</option>
                  <option>Amy</option>
                  <option>Capslog</option>
                  <option>Consulting</option>
                  <option>Other</option>
                </select>
              </div>

              <div class="form-group col-12">
                <label for="textarea"class="webfont text-white">Message:</label>
                <textarea class="form-control" id="message" rows="3" name="textarea" [(ngModel)]="textarea" placeholder="Text:" required></textarea>
              </div>
              <div class="form-group col-sm-12">
                <label for="inputSubmit" class="text-white col-3"></label>
                <input type="submit" class="button-cta-learn-more col-12 col-lg-6 mt-3" (click)="validator()" data-toggle="modal" data-target="#formMessage" value="Submit" name="inputSubmit" id="inputSubmit">
              </div>

              <!-- Modal -->
              <div class="modal fade" id="formMessage" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div class="modal-dialog" role="document">
                  <div class="modal-content">
                      <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">{{messageForm}}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div> <!--End of Modal -->
              </div>
            </form>  <!-- End of Form -->
          </div>
        </div>
      </div>
      </div>
    </div>
    
    <!--footer-->

    <section class="footer-section col-12" id="contact-us">
      <div class="footer-block container col-10">
        <div class="row justify-content-center">
          <div class="col-10 col-md-6 col-lg-2">
              <img src="assets/images/gnc-white-logo.png" alt="" class="img-fluid d-block">
          </div>
          <div class="social-icons justify-content-center col-12 col-lg-2 ml-lg-auto">
              <img src="assets/images/facebook.png" alt="" class="facebook">
              <img src="assets/images/telegram.png" alt="" class="message">
              <img src="assets/images/callphone.png" alt="" class="call">
              <img src="assets/images/linkedin.png" alt="" class="linkedin">   
          </div>
        </div>
      </div>
  </section>

    <!-- Optional JavaScript -->
    <!-- jQuery first, then Popper.js, then Bootstrap JS -->
    <script src="https://code.jquery.com/jquery-3.5.1.slim.min.js" integrity="sha384-DfXdz2htPH0lsSSs5nCTpuj/zy4C+OGpamoFVy38MVBnE+IbbVYUew+OrCXaRkfj" crossorigin="anonymous"></script>
    <script src="https://cdn.jsdelivr.net/npm/popper.js@1.16.1/dist/umd/popper.min.js" integrity="sha384-9/reFTGAW83EW2RDu2S0VKaIzap3H66lZH81PoYlFhbGU+6BZp6G7niu735Sk7lN" crossorigin="anonymous"></script>
    <script src="https://stackpath.bootstrapcdn.com/bootstrap/4.5.2/js/bootstrap.min.js" integrity="sha384-B4gt1jrGC7Jh4AgTPSdUtOBvfO8shuf57BaghqFfPlYxofvL8/KUEfYiJOMMV+rV" crossorigin="anonymous"></script>
  </body>
</html>
